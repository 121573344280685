<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <b-alert variant="danger">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link"> Shop </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card no-body>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                src="@/assets/images/banner/banner-1.jpg"
                alt="`Image of `"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>Product Name</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <span>by</span>
              <b-link class="company-name"> Apple </b-link>
            </b-card-text>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">$12523</h4>
            </div>

            <!-- Avability -->
            <b-card-text
              >Available -
              <span class="text-success">In stock</span></b-card-text
            >

            <!-- Product Description -->
            <b-card-text
              >On Retina display that never sleeps, so it’s easy to see the time
              and other important information, without raising or tapping the
              display. New location features, from a built-in compass to current
              elevation, help users better navigate their day, while
              international emergency calling1 allows customers to call
              emergency services directly from Apple Watch in over 150
              countries, even without iPhone nearby. Apple Watch Series 5 is
              available in a wider range of materials, including aluminium,
              stainless steel, ceramic and an all-new titanium.
              </b-card-text
            >

            <hr />

            <hr />

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
              >
                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                <span>Convert Cart to order</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
  },
  methods:{
    
  }
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
